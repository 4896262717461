<template >
  <router-view />
</template>

<script>
import { useIdle } from '@vueuse/core';

export default {
  name: 'App',

  components: {},

  data: () => ({
    sleepData: useIdle(5000),
  }),
  methods: {
    dosomethong() {
      console.log('we');
    },
  },
  watch: {
    sleepData: {
      deep: true,

      handler: function (val) {
        // console.log('Idle Time', val.idle, val.lastActive);
      },
    },
  },
};
</script>
