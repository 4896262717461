import axios from 'axios';

// https://coderethinked.com/how-to-cancel-a-request-using-axios-in-vue-js/
const http = axios.create({
  // baseURL: 'http://localhost:8080/api/v1/',

  baseURL: 'https://apisaipavaninew.digitelex.in/public/api/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

http.interceptors.request.use(
  (config: any) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers.Authorization =
        'Bearer ' + sessionStorage.getItem('token');
      config.headers.accept = 'application/json';
    }
    // config.timeout = 90000;
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
http.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (err) => {
    console.log(err);
    console.log(err.response?.status || 500);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const status = err.response?.status || 500;
    // we can handle global errors here
    switch (status) {
      // authentication (token related issues)
      case 401: {
        sessionStorage.removeItem('token');
        // window.location = '/login';
        window.location.replace('/login');

        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // forbidden (permission related issues)
      case 403: {
        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // bad request
      case 400: {
        // return Promise.reject({ message: err.message, code: 400 });
        break;
      }

      // not found
      case 404: {
        // return Promise.reject({ message: err.message, code: 404 });
        break;
      }

      // conflict
      case 409: {
        // return Promise.reject({ message: err.message, code: 409 });
        break;
      }

      // unprocessable
      case 422: {
        // return Promise.reject({ message: err.message, code: 422 });
        break;
      }
      // unprocessable
      case 500: {
        // return Promise.reject({ message: err.message, code: 500 });
        break;
      }

      // generic api error (server related) unexpected
      default: {
        // return Promise.reject({ message: err.message, code: 500 });
        break;
      }
    }
    return err.response;
  },
  // function (err) {
  // Do something with response error
  // return Promise.reject(error);

  // },
);
export default http;
