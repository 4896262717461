import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Home',
    component: () => import('./../modules/login/index.vue'),
    children: [
      {
        path: '',
        component: () => import('./../modules/login/components/index.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('./../layout/index.vue'),
    children: [
      {
        path: '',
        component: () => import('./../modules/dashboard/index.vue'),
      },
      {
        path: 'locations',
        component: () => import('./../modules/locations/locations.vue'),
        meta: {
          pageTitle: 'Locations',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Locations', disabled: true },
          ],
          // actions: [
          //   { icon: 'mdi-domain', emitter: 'location-domain-click' },
          //   { icon: 'mdi-dialpad', emitter: 'location-dialpad-click' },
          //   { icon: 'mdi-email', emitter: 'location-email-click' },
          //   { icon: 'mdi-call-split', emitter: 'location-callsplit-click' },
          //   {
          //     icon: 'mdi-arrow-up-bold-box-outline',
          //     emitter: 'location-arrowup-click',
          //   },
          // ],
        },
      },
      {
        path: 'branches',
        component: () => import('./../modules/branches/branches.vue'),
        meta: {
          pageTitle: 'Branches',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Branches', disabled: true },
          ],
        },
      },
      {
        path: 'godowns',
        component: () => import('./../modules/godown/godown.vue'),
        meta: {
          pageTitle: 'Godowns',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Godowns', disabled: true },
          ],
        },
      },
      {
        path: 'content',
        component: () => import('./../modules/content/content.vue'),
        meta: {
          pageTitle: 'Content',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Content', disabled: true },
          ],
        },
      },
      {
        path: 'designation',
        component: () => import('./../modules/designation/designation.vue'),
        meta: {
          pageTitle: 'Designation',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Designation', disabled: true },
          ],
        },
      },
      {
        path: 'employees',
        component: () => import('./../modules/employees/employees.vue'),
        meta: {
          pageTitle: 'Employees',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Employees', disabled: true },
          ],
        },
      },
      {
        path: 'customers',
        component: () => import('./../modules/customers/customers.vue'),
        meta: {
          pageTitle: 'Customers',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Customers', disabled: true },
          ],
        },
      },
      {
        path: 'vehicles',
        component: () => import('./../modules/vehicles/vehicles.vue'),
        meta: {
          pageTitle: 'Vehicles',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Vehicles', disabled: true },
          ],
        },
      },
      {
        path: 'users',
        component: () => import('./../modules/users/users.vue'),
        meta: {
          pageTitle: 'Users',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Masters', disabled: true },
            { text: 'Users', disabled: true },
          ],
        },
      },
      {
        path: 'lr',
        component: () => import('./../modules/lr/lrs.vue'),
        meta: {
          pageTitle: 'LR',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Entries', disabled: true },
            { text: 'Lr', disabled: true },
          ],
        },
      },
      {
        path: 'memo',
        component: () => import('./../modules/memo/memos.vue'),
        meta: {
          pageTitle: 'Memo',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Entries', disabled: true },
            { text: 'Memo', disabled: true },
          ],
        },
      },
      {
        path: 'expense',
        component: () => import('./../modules/expenses/expenses.vue'),
        meta: {
          pageTitle: 'Expenses',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Entries', disabled: true },
            { text: 'Expenses', disabled: true },
          ],
        },
      },
      {
        path: 'freightreceipt',
        component: () =>
          import('./../modules/freightreceipt/freightreceipt.vue'),
        meta: {
          pageTitle: 'Freight Receipt Generate',
          breadcrumb: [
            { text: 'Home', href: '/', disabled: false },
            { text: 'Entries', disabled: true },
            { text: 'Freight Receipt Generate', disabled: true },
          ],
        },
      },
      {
        path: '*',
        component: () => import('./../modules/utils/404.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
