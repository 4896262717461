export const locationModule = {
  namespaced: true,
  state: () => ({
    apple: true,
    peach: false,
    plum: true,
  }),
  mutations: {},
  action: {},
};
